<template>
    <v-select
        :multiple="multiple"
        :filterable="true"
        :options="options"
        v-model="selected"
        :clearable="false"
        :placeholder="placeholder"
    />
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String,
        },
        value: {
            type: Object,
        },
        sto_id: {
            type: Number,
        }
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/outbound/sto-processing/drivers",
            options: [],
            selected: (this.multiple) ? [] : null
        };
    },
    methods: {
        get() {
            this.$http.get(this.baseUrl, {
                params: {
                    order: "licence_number",
                    sort: "asc",
                    stock_id: this.sto_id,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    resp.data.records.map(function (x) {
                        return (x.label = (x.licence_type ? x.licence_type : 'null') + " / " + (x.licence_number ? x.licence_number : 'null') + " - " + x.name);
                    });

                    this.options = resp.data.records;
                } else {
                    this.options = []
                    console.log(resp.data);
                }
            })
            .catch(error => {
                console.log(error)
            });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        },
        draw() {
            this.get()
        }
    },
    mounted() {
        // this.get();
    }
};
</script>